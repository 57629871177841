<template>
  <div>
    <nav-light />
    <section
      class="relative md:h-[200px] md:py-0 py-10 items-center overflow-hidden bg-gradient-to-t to-teal-600 via-teal-600/50 from-transparent"
      id="home"
    >
      <div class="container relative">
        <div class="grid grid-cols-1 md:mt-20 mt-0 text-center">
          <h4 class="font-bold lg:leading-normal leading-normal tracking-wide text-4xl lg:text-5xl capitalize text-white mb-5"></h4>
        </div><!--end grid-->
      </div><!--end container-->
    </section>
    <div class="">
      <div class="container">
        <div class="grid grid-cols-12 gap-5">
          <div class="col-span-8">
            <div class="w-full h-auto mb-5">
              <img
                :src="record.path"
                alt=""
                srcset=""
                class="w-full h-full object-top"
              >
            </div>
            <div>
              <div>
                <span class="font-light text-gray-500">Author : {{ record.author }}</span>
              </div>
              <div>
                <span class="font-light text-gray-500">Tanggal : {{ record.tanggal }}</span>
              </div>
              <div
                class="py-5"
                v-html="record.content"
              ></div>
            </div>
          </div>
          <div class="col-span-4">
            <div class="border border-teal-100 bg-teal-200 px-5">
              <h1 class="font-bold text-2xl text-gray-500 ">Berita Lainnya</h1>
            </div>
            <div>
              <div
                v-for="item in records"
                :key="item.id"
                class="my-3"
              >
                <div class="w-full h-[200px] flex gap-y-3 mb-5 overflow-hidden rounded-md">
                  <img
                    :src="item.path"
                    alt=""
                    srcset=""
                    class="hover:scale-125 hover:cursor-pointer transition-all duration-300 w-full h-full object-top"
                    @click="fetchRecordSelf(item.id)"
                  >
                </div>
                <div
                  @click="fetchRecordSelf(item.id)"
                  class="hover:cursor-pointer"
                >
                  {{ item.title }} <span class="text-gray-400 text-xs">( {{ item.tanggal }} )</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseurl } from "../config/setting";
import NavLight from "@/components/NavLight.vue";
export default {
  components: {
    NavLight,
  },
  data: () => ({
    record: {},
    records: {},
    baseurl: baseurl,
  }),

  methods: {
    fetchRecord: async function () {
      try {
        const endpoint =
          this.baseurl + "/api/v1/news-detail/" + this.$route.params.id;

        let {
          data: { data },
        } = await axios.get(endpoint);

        this.record = data;
      } catch (error) {}
    },
    fetchRecordSelf: async function (payload) {
      try {
        const endpoint = this.baseurl + "/api/v1/news-detail/" + payload;

        let {
          data: { data },
        } = await axios.get(endpoint);

        this.record = data;
      } catch (error) {}
    },
    fetchRecords: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/top-news";
        let {
          data: { success, status, message, data },
        } = await axios.get(endpoint);

        this.records = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchRecord();
    this.fetchRecords();
  },
};
</script>