<template>
  <footer class="footer bg-cyan-800 relative text-gray-200 dark:text-gray-200">
    <div class="py-[30px] px-0 border-t border-slate-800">
      <div class="container relative text-center">
        <div class="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center">
          <div class="lg:col-span-3 md:text-start text-center">
            <a
              href="#"
              class="text-[22px] focus:outline-none"
            >
              <img
                src="/logo-mpp.png"
                class="mx-auto md:me-auto md:ms-0 "
                alt=""
                style="width: 50px"
              >
            </a>

          </div>

          <div class="lg:col-span-5 text-center mt-6 md:mt-0">
            <p class="mb-0">© {{date}} Mall Pelayanan Publik Kota Cilegon</p>
            <div>
              Alamat: Jl. Jend. Sudirman. 2 Gedung Graha Edhi Praja, RT.4/RW.1, Ramanuju, Kec. Purwakarta, Kota Cilegon, Banten 42431 Telp. (0254) 7870543
            </div>
          </div>

          <ul class="lg:col-span-4 list-none md:text-end text-center mt-6 md:mt-0">
            <li class="inline"><a
                href="https://www.youtube.com/@mpp.cilegon"
                target="_blank"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="youtube"
                  class="size-4 align-middle"
                  title="youtube"
                ></i></a></li>

            <li class="inline"><a
                href="https://www.instagram.com/mpp.cilegon"
                target="_blank"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="instagram"
                  class="size-4 align-middle"
                  title="instagram"
                ></i></a></li>

            <li class="inline"><a
                href="mailto:mpp@cilegon.go.id"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="mail"
                  class="size-4 align-middle"
                  title="email"
                ></i></a></li>
          </ul><!--end icon-->
        </div><!--end grid-->
      </div><!--end container-->
    </div>
  </footer>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  mounted() {
    feather.replace();
  },
};
</script>

