<template>
  <section
    class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
    id="services"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Maklumat Pelayanan</h3>
      </div><!--end grid-->

      <div class="grid ">
        <img
          :src="maklumat ? maklumat.file_image:''"
          alt=""
          srcset=""
        >
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>
  
  <script>
import feather from "feather-icons";
import axios from "axios";
import { baseurl } from "../config/setting";

export default {
  data: () => ({
    baseurl: baseurl,
    jmltenant: null,
    jmlloket: null,
    maklumat: null,
  }),

  methods: {
    fetchMaklumat: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/maklumat";
        let {
          data: { data },
        } = await axios.get(endpoint);
        this.maklumat = data;
        console.log(data);
      } catch (error) {}
    },
  },

  mounted() {
    feather.replace();
    this.fetchMaklumat();
  },
};
</script>