<template>
  <section
    class="realtive md:py-24 py-16"
    id="fasilitas"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Fasilitas</h3>

      </div><!--end grid-->

      <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
        <div class="lg:col-span-4 md:col-span-5">
          <div class="sticky top-20">
            <ul
              class="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li
                role="presentation"
                v-for="(item,index) in records"
                :key="index"
                class="py-2"
              >
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500"
                  :class="activeindex === index ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange(index)"
                >
                  <span class="text-lg mt-2 block">{{ item.name }}</span>

                </button>
              </li>

            </ul>
          </div>
        </div>

        <div class="lg:col-span-8 md:col-span-7">
          <div
            id="myTabContent"
            class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
          >
            <div
              :class="activeindex !== index ? 'hidden' : '' "
              role="tabpanel"
              aria-labelledby="profile-tab"
              v-for="(item,index) in records"
              :key="index"
            >
              <img
                :src="item.path"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">{{ item.name }}</h5>
                <p class="text-slate-400 mt-4">{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>


<script>
import feather from "feather-icons";
import axios from "axios";
import { baseurl } from "../config/setting";
export default {
  data: () => ({
    activeindex: 0,
    baseurl: baseurl,
    records: [],
  }),
  mounted() {
    this.fetchRecord();
  },

  methods: {
    tabChange(index) {
      this.activeindex = index;
    },
    fetchRecord: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/facilities";
        let {
          data: { data },
        } = await axios.get(endpoint);

        this.records = data;
      } catch (error) {}
    },
  },
};
</script>