<template>
  <div class="h-screen w-screen flex-col">
    <div class="">
      <router-view />
      <Footer />
    </div>
    <switcher />
  </div>
</template>


<script>
import feather from "feather-icons";
import Switcher from "@/components/Switcher.vue";
import Footer from "@/components/Footer.vue";

export default {
  mounted() {
    feather.replace();
  },
  components: {
    Switcher,
    Footer,
  },
};
</script>
