<template>
  <section
    class="relative md:py-24 py-16"
    id="news"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Berita Terbaru</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Berikut daftar berita terbaru atau informasi terbaru dari Mall Pelayanan Publik Kota Cilegon.</p>
      </div><!--end grid-->

      <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
        <div
          v-for="item in records"
          :key="item.id"
          class="group relative overflow-hidden"
        >
          <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-800 w-[300px] h-[200px]">
            <img
              :src="item.path"
              class="group-hover:scale-110 duration-500"
              alt=""
            >
          </div>

          <div class="mt-6">
            <div class="flex mb-4">
              <span class="flex items-center text-slate-400 text-sm"><i
                  data-feather="calendar"
                  class="size-4 text-slate-900 dark:text-white me-1.5"
                ></i>{{item.tanggal}}</span>
              <span class="flex items-center text-slate-400 text-sm ms-3"><i
                  data-feather="clock"
                  class="size-4 text-slate-900 dark:text-white me-1.5"
                ></i>5 min read</span>
            </div>

            <a
              href=""
              @click="openDetailPage(item.id)"
              class="title text-lg font-semibold hover:text-teal-500 duration-500 ease-in-out"
            >{{item.title}}</a>
            <p
              class="text-slate-400 mt-2"
              v-html="item.sub_title"
            ></p>

            <div class="mt-3">
              <a
                href=""
                class="text-teal-500"
                @click="openDetailPage(item.id)"
              >Selengkapnya <i class="mdi mdi-chevron-right align-middle"></i></a>
            </div>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>

<script>
import feather from "feather-icons";
import axios from "axios";
import { baseurl } from "../config/setting";
export default {
  data: () => ({
    records: [],
    baseurl: baseurl,
  }),
  methods: {
    fetchRecords: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/top-news";
        let {
          data: { success, status, message, data },
        } = await axios.get(endpoint);

        this.records = data;
      } catch (error) {
        console.log(error);
      }
    },
    openDetailPage: function (payload) {
      this.$router.push({
        name: "news-detail",
        params: {
          id: payload,
        },
      });
    },
  },
  mounted() {
    this.fetchRecords();
    feather.replace();
  },
};
</script>